<template>
<div>
	<Slider />
	<section class="bg-white welcome">
		<div class="container">
			<div class="row">
				<div class="col-md-6">
					<img src="img/home-welcome.png" alt="" class="welcome-photo">
				</div>
				<div class="col-md-6">
					<div class="welcome-message">
						<h3>Where work and play come together</h3>
						<p>At East Bridge International School we pride ourselves on placing the children at the heart of everything we do to ensure that they achieve the best possible educational outcomes. We strive to develop well-rounded students able to demonstrate independence, leadership, care, self-reflection and a strong work ethic.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="bg-yellow">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="why-choose-us">
						<h4>our difference</h4>
						<h1>Why Many Parents Choose Us</h1>
						<div class="reasons">
							<div class="reason-item">
								<span>01</span>
								<p>We have Small class size that allow for quality & We produce lifelong learners</p>
							</div>
							<div class="reason-item">
								<span>02</span>
								<p>We offer experiential learning with the child at the center of the learning process</p>
							</div>
							<div class="reason-item">
								<span>03</span>
								<p>We produce learners who are confident, innovative, engaged, reflective and responsible </p>
							</div>
							<div class="reason-item">
								<span>04</span>
								<p>We provide an environment were each student, regardless of ability, feel they count, are nurtured, valued and cared for.</p>
							</div>
							<div class="reason-item">
								<span>05</span>
								<p>We have well trained staff who have the right skills to guide learning.</p>
							</div>
							<div class="reason-item">
								<span>06</span>
								<p>We provide enough teaching materials to support visual, auditory and kinesthetic learners thereby making learning fun and understanding.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="bg-primary">
        <div class="container">
			<div class="video-section">
				<h4>our difference</h4>
				<h1>What our students say about us</h1>
				<video src="/videos/video.mp4" controls autoplay />
			</div>
		</div>
    </section>

	<section class="bg-white">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="why-choose-us">
						<h4>What is special about EBIS</h4>
						<h1>Vision & Mission Statements</h1>
						<div class="special">
							<div class="special-item">
								<h3>Our Vision</h3>
								<p>Our vision is to be the best leading international school in Africa, committed to transforming the lives of our students to become successful active global citizens.</p>
							</div>
							<div class="special-item">
								<h3>Our Mission</h3>
								<p>To empower students with a world class, lifelong and high-quality education that inspires them to achieve their full potentials with excellence and ultimately succeed in life.</p>
							</div>
							<div class="special-item">
								<h3>Philosophy</h3>
								<p>We believe that a teacher-guided, balanced approach establishes an excellent educational foundation.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	

	
	

</div>
</template>

<script>
export default {
	data() {
		return {
			
		};
	},
	components: {
		Slider: () => import("@/components/Slider.vue"),
	},
};
</script>
